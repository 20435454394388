<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="可参与地理区域"
                    prop="restrict_province">
        <Address v-model="address"
                 @change="addressChange" />
      </el-form-item>

      <el-form-item label="渠道号"
                    class="width300"
                    prop="req_channel_id">
        <el-input type="text"
                  placeholder="请输入渠道号"
                  v-model="form.req_channel_id"></el-input>
      </el-form-item>
      <el-form-item label="机构号"
                    class="width300"
                    prop="institution_no">
        <el-input type="text"
                  placeholder="请输入机构号"
                  v-model="form.institution_no"></el-input>
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动logo图">
        <ImgUpload :fileList="imgList"
                   pixel="单图建议120*120像素，若上传多图则拼接为长图"
                   v-model="form.logo_img_id" />
      </el-form-item>
      <el-form-item label="活动背景图">
        <ImgUpload :fileList="imgList2"
                   pixel="单图建议640*862像素，若上传多图则拼接为长图"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="活动背景图">
        <ImgUpload :fileList="imgList3"
                   pixel="单图建议640*640像素"
                   v-model="form.activity_code_img_id" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="新老会员游戏方式"
                    required>
        <el-radio-group v-model="form.all_type"
                        @change="form.prize_config.all_prize=[]"
                        :disabled="form.id?true:false">
          <el-radio :label="1">同一方式</el-radio>
          <el-radio :label="2">不同方式</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="游戏方式"
                    v-if="form.all_type === 1"
                    required>
        <el-radio-group v-model="form.all_prize_type"
                        :disabled="form.id?true:false"
                        @change="allPrizeTypeChange">
          <el-radio label="BATCH_COUPON">批量领券</el-radio>
          <el-radio label="LUCKY">九宫格抽奖</el-radio>
        </el-radio-group>
        <Prizes :prize_config.sync="form.prize_config.all_prize"
                @delete="deletePrize"
                :type="form.all_prize_type === 'LUCKY'?'LUCKY':'COUPON'" />
      </el-form-item>
      <el-form-item label="新会员游戏方式"
                    v-if="form.all_type === 2"
                    required>
        <el-radio-group v-model="form.new_member_prize_type"
                        :disabled="form.id?true:false"
                        @change="form.prize_config.new_member_prize=[]">
          <el-radio label="BATCH_COUPON">批量领券</el-radio>
          <el-radio label="LUCKY">九宫格抽奖</el-radio>
        </el-radio-group>
        <Prizes :type="form.new_member_prize_type === 'LUCKY'?'LUCKY':'COUPON'"
                @delete="deletePrize"
                :prize_config.sync="form.prize_config.new_member_prize" />
      </el-form-item>
      <el-form-item label="老会员游戏方式"
                    v-if="form.all_type === 2"
                    required>
        <el-radio-group v-model="form.old_member_prize_type"
                        :disabled="form.id?true:false"
                        @change="form.prize_config.old_memrber_prize=[]">
          <el-radio label="BATCH_COUPON">批量领券</el-radio>
          <el-radio label="LUCKY">九宫格抽奖</el-radio>
          <el-radio label="NOT_TAKE_PART">不可参与</el-radio>
        </el-radio-group>
        <Prizes :prize_config.sync="form.prize_config.old_memrber_prize"
                @delete="deletePrize"
                :type="form.old_member_prize_type === 'LUCKY'?'LUCKY':'COUPON'"
                v-if="form.old_member_prize_type !== 'NOT_TAKE_PART'" />
      </el-form-item>
      <el-form-item label="活动时间"
                    required>
        <WxDate @change="dateChange"
                ref="wxDate"
                :dateRule="dateRule" />
      </el-form-item>
      <el-form-item label="活动每天可参与次数"
                    class="width300"
                    prop="day_num">
        <el-input type="text"
                  placeholder="请输入活动每天可参与次数"
                  v-model.number="form.day_num"></el-input>
      </el-form-item>
      <el-form-item label="每个用户可参与总次数"
                    class="width300"
                    prop="user_total_num">
        <el-input type="text"
                  placeholder="请输入每个用户可参与总次数"
                  v-model.number="form.user_total_num"></el-input>
      </el-form-item>
      <el-form-item label="每个用户每天可参与总次数"
                    class="width300"
                    prop="user_day_num">
        <el-input type="text"
                  placeholder="每个用户每天可参与总次数"
                  v-model.number="form.user_day_num"></el-input>
      </el-form-item>
      <el-form-item label="初始参与数"
                    class="width300"
                    prop="init_num">
        <el-input type="text"
                  placeholder="请输入初始参与数"
                  v-model.number="form.init_num"></el-input>
      </el-form-item>
      <el-form-item label="员工是否可以直接派发">
        <el-radio-group v-model="form.is_marketer_issues">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否限制员工参与">
        <el-radio-group v-model="form.is_restrict_marketer_receive">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Editor from '@/components/Editor'
import Address from '@/components/Address'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
import WxDate from '@/components/WxDate'
import Prizes from './components/Prizes'
export default {
  name: 'SetMemberLucky',
  data () {
    return {
      tableData: [],
      imgList: [],
      imgList2: [],
      imgList3: [],
      areaAddress: {},
      address: {},
      dateTime: [],
      dateRule: {},
      form: {
        type: 'CRM',
        title: '',
        started_at: '',
        is_take_part: false,
        ended_at: '',
        rule: '',
        img_id: '',
        logo_img_id: '',
        activity_code_img_id: '',
        draw_type: 1,
        prize_config: {
          all_prize: [],
          new_member_prize: [],
          old_memrber_prize: []
        },
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        time_rule: 'ANY',
        time_rule_day: [[]],
        time_rule_time: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7],
        delete_prize_ids: [],
        restrict_province: '',
        restrict_city: '',
        restrict_district: '',
        req_channel_id: '', // 渠道号
        institution_no: '', // 机构号
        all_type: 1,
        all_prize_type: 'BATCH_COUPON',
        new_member_prize_type: 'BATCH_COUPON',
        old_member_prize_type: 'BATCH_COUPON',
        day_num: '',
        user_total_num: 1,
        user_day_num: 1,
        init_num: 0,
        is_marketer_issues: true,
        is_restrict_marketer_receive: false
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        req_channel_id: [
          { required: true, message: '请输入渠道号', trigger: 'blur' }
        ],
        institution_no: [
          { required: true, message: '请输入机构号', trigger: 'blur' }
        ],
        list_province: [
          { required: true, message: '至少选择省份', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        each_num: [
          { required: true, message: '请输入每用户最高可参与数', trigger: 'blur' }
        ],
        init_num: [
          { required: true, message: '请输入初始参与数', trigger: 'blur' }
        ],
        user_total_num: [
          { required: true, message: '每个用户可参与总次数', trigger: 'blur' }
        ],
        user_day_num: [
          { required: true, message: '每个用户每天可参与总次数', trigger: 'blur' }
        ],
        day_num: [
          { required: true, message: '请输入活动每天可参与次数', trigger: 'blur' }
        ]

      },
      prizeTypes: [
        {
          value: 'ENVELOPE',
          label: '红包'
        },
        {
          value: 'COUPON',
          label: '代金券'
        },
        {
          value: 'KIND',
          label: '邮寄实物'
        },
        {
          value: 'VERIFY',
          label: '网点核销'
        },
        {
          value: 'REFUEL',
          label: '加油券'
        }
      ]
    }
  },
  components: {
    Area,
    ImgUpload,
    Editor,
    Address,
    WxDate,
    Prizes
  },

  computed: {

  },
  methods: {
    deletePrize (e) {
      this.form.delete_prize_ids.push(e)
    },
    checkPrize () {
      if (this.form.all_type === 1) {
        if (this.form.prize_config.all_prize.length === 0) {
          this.$message.error('请添加奖品')
          return false
        } else {
          return true
        }
      } else {
        if (this.form.prize_config.new_member_prize.length === 0) {
          this.$message.error('请添加新会员奖品')
          return false
        } else if (this.form.prize_config.old_memrber_prize.length === 0) {
          if (this.form.old_member_prize_type === 'NOT_TAKE_PART') {
            return true
          } else {
            this.$message.error('请添加老会员奖品')
            return false
          }
        } else {
          return true
        }
      }
    },
    allPrizeTypeChange () {
      this.form.prize_config.all_prize = []
    },
    addressChange (res) {
      this.form.restrict_province = res.province
      this.form.restrict_city = res.city
      this.form.restrict_district = res.district
    },
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      const wxDateStatus = this.$refs.wxDate.validate()
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!wxDateStatus) { return }
          if (!this.checkPrize()) { return }
          const formData = JSON.parse(
            JSON.stringify(this.form)
          )
          formData.prize_config.all_prize.map(item => {
            if (item.money) { item.money = item.money * 100 }
          })
          formData.prize_config.new_member_prize.map(item => {
            if (item.money) { item.money = item.money * 100 }
          })
          formData.prize_config.old_memrber_prize.map(item => {
            if (item.money) { item.money = item.money * 100 }
          })

          const res = await setActivity(formData)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('memberLucky')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        activity.activity_id = id
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList2, 0, activity.img_data)
        activity.logo_img_id = getImgId(activity.logo_img_data)
        activity.logo_img_data && this.$set(this.imgList, 0, activity.logo_img_data)
        activity.activity_code_img_id = getImgId(activity.activity_code_img_data)
        activity.activity_code_img_data && this.$set(this.imgList3, 0, activity.activity_code_img_data)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.address = {
          province: activity.restrict_province,
          city: activity.restrict_city,
          district: activity.restrict_district
        }
        this.dateRule = {
          time_rule: activity.time_rule,
          started_at: activity.started_at,
          ended_at: activity.ended_at,
          time_rule_week: activity.time_rule_week,
          time_rule_time: activity.time_rule_time,
          time_rule_day: activity.time_rule_day
        }
        this.dateTime = [activity.started_at, activity.ended_at]
        activity.prize_config.all_prize.map((item) => {
          if (item.img_data) {
            item.img_id = item.img_data.id
          }
          if (item.refuel_img_data) {
            item.refuel_img_id = item.refuel_img_data.id
          }
          if (item.money > 0) {
            item.money /= 100
          }
        })
        activity.prize_config.new_member_prize.map((item) => {
          if (item.img_data) {
            item.img_id = item.img_data.id
          }
          if (item.refuel_img_data) {
            item.refuel_img_id = item.refuel_img_data.id
          }
          if (item.money > 0) {
            item.money /= 100
          }
        })
        activity.prize_config.old_memrber_prize.map((item) => {
          if (item.img_data) {
            item.img_id = item.img_data.id
          }
          if (item.refuel_img_data) {
            item.refuel_img_id = item.refuel_img_data.id
          }
          if (item.money > 0) {
            item.money /= 100
          }
        })
        // const prizeConfig = JSON.parse(
        //   JSON.stringify(activity.prize_config)
        // )
        // this.prize_config = []
        // prizeConfig.map((item, index) => {
        //   item.money = item.money / 100
        //   item.img_id = item.img.id
        //   item.imgList = [item.img]
        // })
        // activity.prize_config = prizeConfig
        this.form = activity
        this.form.delete_prize_ids = []
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
