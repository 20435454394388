<template>
  <div class="">
    <el-dialog title="设置奖品"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="60%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="奖品类型"
                      prop="type">
          <el-select v-model="form.type"
                     @change="typeChange()"
                     class="width100"
                     placeholder="请选择">
            <el-option v-for="(item,index) in prizeTypes"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖品图片"
                      v-if="type=== 'LUCKY'"
                      prop="img_id">
          <ImgUpload :fileList="imgList"
                     @on-success="imgUploadSuccess"
                     v-model="form.img_id" />
        </el-form-item>
        <el-form-item label="奖品名称"
                      v-if="form.type === 'KIND' || form.type === 'VERIFY'|| form.type === 'CARD_KEY'|| form.type === 'REFUEL'"
                      prop="name">
          <el-input type="text"
                    placeholder="请输入奖品名称"
                    v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="红包金额"
                      v-if="form.type === 'ENVELOPE'"
                      prop="money">
          <el-input type="text"
                    placeholder="红包金额"
                    v-model="form.money"></el-input>
        </el-form-item>
        <el-form-item label="代金券"
                      v-if="form.type === 'COUPON'"
                      prop="coupon_id">
          <el-select v-model="form.coupon_id"
                     filterable
                     class="width100"
                     remote
                     @change="couponChange"
                     placeholder="请输入批次号"
                     :remote-method="remoteMethod">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
              <!-- <div @click="couponClick(item)">
                {{item.label}}
              </div> -->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡密id"
                      v-if="form.type === 'CARD_KEY'"
                      prop="card_id">
          <el-input type="text"
                    placeholder="卡密id"
                    v-model="form.card_id"></el-input>
        </el-form-item>
        <el-form-item label="奖品数量"
                      prop="total_inventory">
          <el-input type="text"
                    placeholder="请输入奖品数量"
                    v-model.number="form.total_inventory"></el-input>
        </el-form-item>
        <el-form-item label="提货码界面说明"
                      v-if="form.type === 'VERIFY' ">
          <Editor v-model="form.desc" />
        </el-form-item>
        <el-form-item label="加油券id"
                      v-if="form.type === 'REFUEL'"
                      prop="refuel_id">
          <el-input type="text"
                    placeholder="请输入加油券id"
                    v-model="form.refuel_id"></el-input>
        </el-form-item>
        <el-form-item label="加油券说明"
                      prop="refuel_desc"
                      v-if="form.type === 'REFUEL' ">
          <Editor v-model="form.refuel_desc" />
        </el-form-item>
        <el-form-item label="加油券封面图片"
                      v-if="form.type === 'REFUEL'"
                      prop="refuel_img_id">
          <ImgUpload :fileList="imgList2"
                     @on-success="imgUploadSuccess2"
                     v-model="form.refuel_img_id" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{index!==''?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-button type="primary"
               @click="addPrize">添加奖品</el-button>
    <el-table :data="prizeList"
              stripe>
      <el-table-column label="奖品名称">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'ENVELOPE'">
            {{ scope.row.name || scope.row.money/100+'元现金红包'}}
          </span>
          <span v-else>{{ scope.row.name}}</span>
        </template>
      </el-table-column>

      <el-table-column label="奖品类型">
        <template slot-scope="scope">
          {{ scope.row.type | prizeType}}
        </template>
      </el-table-column>
      <el-table-column prop="address"
                       v-if="type==='LUCKY'"
                       label="奖品图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img_data ? scope.row.img_data.url :''" />
        </template>
      </el-table-column>
      <el-table-column label="已领取数量">
        <template slot-scope="scope">
          {{scope.row.used_quantity || 0}}
        </template>
      </el-table-column>
      <el-table-column prop="total_inventory"
                       label="总库存">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="setPrize(scope)">编辑</el-button>
          <el-button type="text"
                     @click="delPrize(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TableImg from '@/components/TableImg'
import { fetchVouchers } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Editor from '@/components/Editor'
export default {
  props: {
    prize_config: Array,
    type: String
  },
  data () {
    var validatPrices = (rule, value, callback) => {
      if (rule.required === false && (value === '' || value === null || value === undefined)) {
        callback()
      }
      const isPrice = /(^[-]?[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
      if (isPrice.test(value)) {
        callback()
      } else {
        callback(new Error(rule.message || '请输入正确的金额，例：10, 10.00'))
      }
    }
    return {
      prizeList: [],
      imgList: [],
      imgList2: [],
      dialogVisible: false,
      form: {
        gift_id: '',
        name: '',
        type: '',
        img: {},
        img_id: '',
        coupon_id: '',
        card_id: '',
        money: '',
        total_inventory: 0,
        desc: '',
        refuel_id: '',
        refuel_img_id: '',
        refuel_desc: ''

      },
      rules: {
        gift_id: [
          { required: true, message: '请输入礼品id', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择奖品类型', trigger: 'change' }
        ],
        money: [
          { required: true, message: '请输入红包金额', trigger: 'blur' },
          { validator: validatPrices, trigger: 'blur' }
        ],
        coupon_id: [
          { required: true, message: '请输入代金券批次号搜索', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        total_inventory: [
          { required: true, message: '请输入奖品数量', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传奖品图片', trigger: 'change' }
        ],
        card_id: [
          { required: true, message: '请输入卡密id', trigger: 'change' }
        ],
        refuel_id: [
          { required: true, message: '请输入加油券id', trigger: 'change' }
        ],
        refuel_img_id: [
          { required: true, message: '请上传加油券封面图', trigger: 'change' }
        ],
        refuel_desc: [
          { required: true, message: '请输入加油券详细说明', trigger: 'change' }
        ]

      },
      index: '',
      options: []
    }
  },
  computed: {
    prizeTypes () {
      if (this.type === 'LUCKY') {
        return [
          {
            value: 'ENVELOPE',
            label: '红包'
          },
          {
            value: 'COUPON',
            label: '代金券'
          },
          {
            value: 'KIND',
            label: '邮寄实物'
          },
          {
            value: 'VERIFY',
            label: '网点核销'
          },
          {
            value: 'CARD_KEY',
            label: '卡密'
          },
          {
            value: 'REFUEL',
            label: '加油券'
          }
        ]
      } else {
        return [
          {
            value: 'COUPON',
            label: '代金券'
          }
        ]
      }
    }
  },
  filters: {
    prizeType (val) {
      if (val) {
        switch (val) {
          case 'ENVELOPE':
            return '红包'
          case 'COUPON':
            return '代金券'
          case 'KIND':
            return '邮寄实物'
          case 'VERIFY':
            return '网点核销'
          case 'CARD_KEY':
            return '卡密'
          case 'REFUEL':
            return '加油券'
          default:
            return ''
        }
      } else {
        return ''
      }
    }
  },
  components: {
    ImgUpload,
    TableImg,
    Editor
  },
  watch: {
    prize_config: {
      immediate: true, // 这句重要
      handler (val) {
        this.prizeList = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    // couponClick (item) {
    //   this.form.coupon_stock_id = item.coupon_stock_id
    // },
    couponChange (val) {
      const res = this.options.find((item) => {
        return item.value === val
      })
      this.form.coupon_stock_id = res.coupon_stock_id
      this.form.name = res.title
    },
    setPrize (scpoe) {
      this.showForm(scpoe)
    },
    delPrize (scpoe) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        this.prizeList.splice(scpoe.$index, 1)
        this.$emit('update:prize_config', this.prizeList)
        if (scpoe.row.id) {
          this.$emit('delete', scpoe.row.id)
        }
      })
    },
    addPrize () {
      if (this.type === 'LUCKY' && this.prizeList.length > 7) {
        this.$message.error('最多存在8个')
      } else {
        this.showForm()
      }
    },

    typeChange () {
      this.form.name = ''
      this.form.coupon_id = ''
      this.$refs.form.clearValidate()
    },
    async remoteMethod (query) {
      if (query !== '') {
        const res = await fetchVouchers({
          coupon_stock_id: query
        })
        if (res.meta.code === 0) {
          this.options = []
          res.data.coupons.map((item) => {
            this.options.push({
              value: item.id,
              label: `${item.coupon_stock_id}（${item.title}）`,
              title: item.title,
              coupon_stock_id: item.coupon_stock_id
            })
          })
        }
      } else {
        this.options = []
      }
    },
    imgUploadSuccess (res) {
      this.form.img_data = res[0]
    },
    imgUploadSuccess2 (res) {
      this.form.refuel_img_data = res[0]
    },
    showForm (scpoe) {
      this.dialogVisible = true
      this.options = []
      this.$nextTick(() => {
        if (scpoe) {
          this.index = scpoe.$index
          this.imgList = [scpoe.row.img_data]
          this.imgList2 = [scpoe.row.refuel_img_data]
          const formData = Object.assign({}, scpoe.row)

          this.form = formData
          if (formData.type === 'COUPON') {
            this.remoteMethod(formData.coupon_stock_id)
          }
        } else {
          this.form = {
            gift_id: '',
            name: '',
            type: '',
            img_id: '',
            img: {},
            coupon_id: '',
            total_inventory: '',
            money: '',
            refuel_id: '',
            refuel_img_id: '',
            refuel_desc: ''
          }
          this.index = ''
          this.imgList = []
          this.imgList2 = []
        }
        this.$refs.form.clearValidate()
      })
    },

    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          switch (this.form.type) {
            case 'ENVELOPE':
              this.form.name = `${this.form.money}元红包`
              break
            default:
              break
          }
          if (this.index !== '') {
            this.$set(this.prizeList, this.index, this.form)
          } else {
            this.prizeList.push(this.form)
          }
          this.index = ''
          this.$emit('update:prize_config', this.prizeList)
          this.dialogVisible = false
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 15px;
}
</style>
